import React, { ChangeEvent, useState } from 'react';
import { IconButton } from '@contentful/f36-button';
import { Table } from '@contentful/f36-table';
import { TextInput } from '@contentful/f36-forms';
import { PlusCircleIcon } from '@contentful/f36-icons';
import { PinnedProduct } from '../../types/PinnedProduct';

export interface PinnedProductsAddRowProps {
  onPinnedProductAdded: (pinnedProduct: PinnedProduct) => boolean;
}

const PinnedProductsAddRow = (props: PinnedProductsAddRowProps): JSX.Element => {
  const [invalid, setInvalid] = useState<boolean>(false);

  const emptyPinnedProduct: PinnedProduct = {
    mpvId: '',
    position: 0,
  };

  const [newPinnedProduct, setNewPinnedProduct] = useState<PinnedProduct>(emptyPinnedProduct);

  const handleMpvIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewPinnedProduct({ mpvId: event.target.value, position: newPinnedProduct.position });
  };

  const handlePositionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewPinnedProduct({ mpvId: newPinnedProduct.mpvId, position: parseInt(event.target.value) });
  };

  const handleProductAdded = () => {
    const succeeded: boolean = props.onPinnedProductAdded(newPinnedProduct);
    if (succeeded) {
      setNewPinnedProduct(emptyPinnedProduct);
    }
    setInvalid(!succeeded);
  };

  return (
    <Table.Row>
      <Table.Cell>
        <TextInput
          data-testid="mpv-id-add-field"
          isInvalid={invalid}
          value={newPinnedProduct.mpvId}
          onChange={handleMpvIdChange}
        />
      </Table.Cell>
      <Table.Cell>
        <TextInput
          data-testid="position-field"
          type="number"
          value={newPinnedProduct.position === 0 ? '' : newPinnedProduct.position.toString()}
          onChange={handlePositionChange}
        />
      </Table.Cell>
      <Table.Cell>
        <IconButton
          data-testid="add-button"
          variant="transparent"
          icon={<PlusCircleIcon variant="primary" size="large" />}
          aria-label="Add product"
          onClick={handleProductAdded}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default PinnedProductsAddRow;
