import { PinnedProduct } from '../types/PinnedProduct';
import {
  buildValidationResult,
  PinnedProductValidationResult,
} from '../types/PinnedProductValidationResult';

export const validatePinnedProduct = (
  position: number,
  addedPinnedProduct: PinnedProduct,
  productList: PinnedProduct[]
): PinnedProductValidationResult => {
  if (addedPinnedProduct.mpvId === '') {
    return buildValidationResult(false, 'The MPV ID cannot be empty.');
  }

  if (
    productList.find(
      (p: PinnedProduct, i: number) => p.mpvId === addedPinnedProduct.mpvId && position !== i
    )
  ) {
    return buildValidationResult(false, 'The product you are trying to pin is aready pinned.');
  }

  if (addedPinnedProduct.position <= 0) {
    return buildValidationResult(false, 'The pinned position must be a number greater than 0');
  }

  return buildValidationResult(true, '');
};
