import { Button } from '@contentful/f36-button';
import { Box, Flex } from '@contentful/f36-core';
import { Switch, TextInput } from '@contentful/f36-forms';
import React, { useRef, useState, useEffect } from 'react';
import Papa from 'papaparse';
import { PinnedProduct } from '../../types/PinnedProduct';
import { CloudUploadIcon } from '@contentful/f36-icons';
import { validatePinnedProductsFile } from '../../validations/PinnedProductsFileValidation';
import { PinnedProductValidationResult } from '../../types/PinnedProductValidationResult';
import { Notification } from '@contentful/f36-notification';

export interface PinnedProductsBulkUploadProps {
  onProductListUploaded: (
    products: PinnedProduct[],
    preserveConfiguration: boolean,
    startingPosition: number
  ) => boolean;
}

const DEFAULT_START_POSITION = 0;

const PinnedProductsBulkUpload = (props: PinnedProductsBulkUploadProps): JSX.Element => {
  const inputRef: React.RefObject<HTMLInputElement> = useRef(null);
  const [preserveConfiguration, setPreserveConfiguration] = useState<boolean>(true);
  const [useStartPosition, setUseStartPosition] = useState<boolean>(false);
  const [startPosition, setStartPosition] = useState<number>(DEFAULT_START_POSITION);

  useEffect(() => {
    if (!useStartPosition) {
      setStartPosition(DEFAULT_START_POSITION);
    }
  }, [useStartPosition]);

  const productListUploadHandler = (results: Papa.ParseResult<PinnedProduct>): void => {
    const validationResult: PinnedProductValidationResult = validatePinnedProductsFile(
      results,
      startPosition
    );
    if (validationResult.isValid) {
      props.onProductListUploaded(results.data, preserveConfiguration, startPosition);
    } else {
      Notification.error(validationResult.errorMessage);
    }
  };

  const startPositionChangedHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setStartPosition(parseInt(e.target.value));
  };

  const processCsvFile = () => {
    const inputElement: HTMLInputElement | null = inputRef.current;
    if (inputElement && inputElement.files && inputElement.files[0]) {
      Papa.parse(inputElement.files[0], {
        worker: true,
        skipEmptyLines: true,
        header: true,
        dynamicTyping: true,
        complete: productListUploadHandler,
      });
    }
  };

  return (
    <Flex flexDirection="column" gap="spacingL" paddingRight="spacingL">
      <TextInput ref={inputRef} type="file" accept=".csv" />

      <Switch
        isChecked={preserveConfiguration}
        onChange={() => setPreserveConfiguration((prevState) => !prevState)}>
        Preserve existing configuration after upload
      </Switch>
      <Flex flexDirection="row" gap="spacingS" justifyContent="center" alignItems="center">
        <Box
          style={{
            width: '100%',
          }}>
          <Switch
            isChecked={useStartPosition}
            onChange={() => setUseStartPosition((prevState) => !prevState)}>
            Specify a starting position for uploaded configuration
          </Switch>
        </Box>

        <Box
          style={{
            width: '250px',
          }}>
          <TextInput
            value={startPosition === 0 ? '' : startPosition.toString()}
            isDisabled={!useStartPosition}
            type="number"
            onChange={startPositionChangedHandler}
          />
        </Box>
      </Flex>
      <Flex justifyContent="center">
        <Button
          data-testid="upload-button"
          startIcon={<CloudUploadIcon />}
          variant="primary"
          onClick={processCsvFile}>
          Upload
        </Button>
      </Flex>
    </Flex>
  );
};

export default PinnedProductsBulkUpload;
