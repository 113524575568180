import { PinnedProduct } from '../types/PinnedProduct';
import {
  buildValidationResult,
  PinnedProductValidationResult,
} from '../types/PinnedProductValidationResult';

export const validatePinnedProductConfiguration = (configuration: any): boolean => {
  try {
    if (!configuration) {
      return false;
    }

    if (!validateProducts(configuration)) {
      return false;
    }

    if (!validateSchedule(configuration)) {
      return false;
    }

    return true;
  } catch {
    return false;
  }
};

export const validatePinnedProductsMerge = (
  existingProducts: PinnedProduct[],
  toBeMergedProducts: PinnedProduct[]
): PinnedProductValidationResult => {
  const resultingArray = [...existingProducts, ...toBeMergedProducts];

  if (new Set(resultingArray.map((x) => x.position)).size !== resultingArray.length) {
    return buildValidationResult(
      false,
      'The resulting list of pinned products would contain duplicated positions'
    );
  }
  if (new Set(resultingArray.map((x) => x.mpvId)).size !== resultingArray.length) {
    return buildValidationResult(
      false,
      'The resulting list of pinned products would contain duplicated MPV IDs'
    );
  }

  return buildValidationResult(true, '');
};

const validateSchedule = (configuration: any): boolean => {
  if (!configuration.schedule) {
    return false;
  }

  if (
    !('enabled' in configuration.schedule) ||
    typeof configuration.schedule.enabled !== 'boolean'
  ) {
    return false;
  }

  if (
    'startDate' in configuration.schedule &&
    !(new Date(configuration.schedule.startDate) instanceof Date)
  ) {
    return false;
  }

  if (
    'endDate' in configuration.schedule &&
    !(new Date(configuration.schedule.endDate) instanceof Date)
  ) {
    return false;
  }

  return true;
};

const validateProducts = (configuration: any): boolean => {
  if (!('products' in configuration) || !Array.isArray(configuration.products)) {
    return false;
  }

  if (
    !configuration.products.every((product: PinnedProduct) => {
      return validateMpvId(product) && validatePosition(product);
    })
  ) {
    return false;
  }

  return true;
};

const validateMpvId = (product: any): boolean => {
  return 'mpvId' in product && typeof product.mpvId === 'string' && product.mpvId !== '';
};

const validatePosition = (product: any): boolean => {
  return 'position' in product && typeof product.position === 'number' && product.position > 0;
};
