import { Switch } from '@contentful/f36-forms';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Calendar } from '@contentful/f36-datepicker';
import { Schedule } from '../../types/Schedule';
import {
  getDateOnly,
  getEndOfDayForDate,
  getDatesInRange,
} from '../../manipulation/DateManipulation';
import { Flex } from '@contentful/f36-core';
import { DayClickEventHandler } from 'react-day-picker';

export interface PinnedProductsScheduleProps {
  schedule: Schedule;
  onDateRangeChanged: (startDate: Date, endDate: Date) => void;
  onEnabledChanged: (checked: boolean) => void;
}

const PinnedProductsSchedule = (props: PinnedProductsScheduleProps): JSX.Element => {
  const [changingRange, setChangingRange] = useState<boolean>(false);
  const [selectedDays, setSelectedDays] = useState<Date[]>([]);

  const onEnabledChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    props.onEnabledChanged(event.target.checked);
  };

  useEffect(() => {
    if (props.schedule && props.schedule.startDate && props.schedule.endDate) {
      setSelectedDays(
        getDatesInRange(new Date(props.schedule.startDate), new Date(props.schedule.endDate))
      );
    }
  }, [props.schedule, props.schedule?.startDate, props.schedule?.endDate]);

  const handleDayClick: DayClickEventHandler = (day: Date): void => {
    const startDate: Date = getDateOnly(day);
    const endDate = getEndOfDayForDate(startDate);

    setChangingRange(!changingRange);
    if (!changingRange) {
      props.onDateRangeChanged(startDate, endDate);
    } else {
      props.onDateRangeChanged(getDateOnly(props.schedule.startDate), endDate);
    }
  };

  return (
    <Flex flexDirection="column" gap="spacingL" paddingRight="spacingL">
      <Switch
        testId="schedule-enabled-switch"
        isChecked={props.schedule?.enabled ?? false}
        onChange={onEnabledChanged}>
        Enable scheduling
      </Switch>

      <Calendar onDayClick={handleDayClick} selected={selectedDays} numberOfMonths={2} />
    </Flex>
  );
};

export default PinnedProductsSchedule;
