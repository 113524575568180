export const getDateOnly = (date?: Date): Date => {
  const safeDate = date ? new Date(date) : new Date();

  const dayOnly = new Date(safeDate.getFullYear(), safeDate.getMonth(), safeDate.getDate());

  return new Date(dayOnly.getTime() - dayOnly.getTimezoneOffset() * 1000 * 60);
};

export const getEndOfDayForDate = (date?: Date): Date => {
  const dayOnly = getDateOnly(date);
  let milliseconds = dayOnly.getTime();
  milliseconds = milliseconds + 1000 * 60 * 60 * 24 - 1;

  return new Date(milliseconds);
};

export const getDatesInRange = (startDate: Date, stopDate: Date) => {
  var dateArray: Date[] = [];
  var currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate));
    currentDate = new Date(currentDate);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dateArray;
};
